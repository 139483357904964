body {
  margin: 0;
  font-family: "Almarai", sans-serif;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.font {
  font-family: "Almarai", sans-serif;
  background-color: #f9f9f9;
}

.hieght-search {
  height: 80vh;
}

.logo {
  width: 100px;
  height: 100px;
}

.logo2 {
  width: 60px;
  height: 60px;
}

.login-img {
  height: 20px;
  width: 20px;
}

.nav-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
}


.sub-tile {

  font-family: Almarai;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.slider-background {
  height: "296px";
  width: 294.26px;
}

.slider-background2 {
  background: linear-gradient(174.76deg, #d3deff 0%, #a8baff 100%);
  height: "296px";
  width: 294.26px;
}

.slider-background3 {
  background: linear-gradient(176.69deg, #f8f0d5 0%, #efdcab 100%);
  height: "296px";
  width: 294.26px;
}

.slider-background4 {
  background: linear-gradient(175.24deg, #b2e8f0 0%, #7bccdc 100%);
  height: "296px";
  width: 294.26px;
}

.slider-title {
  color: #915970;
  font-family: Almarai;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
}

.slider-text {
  color: #915970;
  font-family: Almarai;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 53px;
  text-align: center;
}

.allCard {
  position: relative;
  height: 130px;
  width: 130px;
}

.categoty-card {
  height: 140px;
  width: 100%;
  border-radius: 50%;
  opacity: 0.8;
  background-color: rgb(4 183 169) !important;
}

.categoty-card-img {
  max-width: 110px;
  max-height: 100px;
  position: absolute;
  top: 20%;
  left: 10%;
}

.categoty-card-text {
  color: #555550;
  font-family: Almarai;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* products card style */

.card-title {
  color: #555550;
  font-family: Almarai;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-price {
  font-family: Almarai;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
}

.card-currency {
  color: #555550;
  font-family: Almarai;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}

.card-rate {
  color: #ffc107;
  font-family: Almarai;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

/* end of card style */
.shopping-now {
  padding: 5px;
  height: 35px;
  width: 90px;
  text-decoration: none;
  text-align: center;
  border: 1px solid;
  border-radius: 14px;
}

.shopping-now:hover {
  background-color: #cb3694;
  border: 1px solid #fff;
  color: #f9f9f9 !important;
  border-radius: 14px;
  cursor: pointer;
}

/* discount setion */
.discount-backcolor {
  height: 130px;
  border-radius: 8px;
  background: radial-gradient(circle, #767676 0%, #4d4f50 100%, #494c4d 100%);
}

.discount-title {
  color: #ffffff;
  font-family: Almarai;
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
}

.dicount-img {
  max-height: 130px;
  max-width: 80%;
}

/* end discount */

/* foter */
.footer-background {
  background-color: #ffffff;
}

.footer {
  width: 100%;
  /* position: fixed;
  bottom: 0; */
}

.footer-shroot {
  color: #979797;
  font-family: Almarai;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  cursor: pointer;
}

.footer-phone {
  color: #979797;
  font-family: "Almarai";
  font-size: 13px;
  font-weight: bold;
  text-align: right;
  cursor: pointer;
}

/* end footer */

/* card filter style */
.search-count-text {
  color: #979797;
  font-family: Almarai;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.card-filter {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 15px;
  width: 170px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-filter-item {
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
}

.card-filter-item:hover {
  background-color: #cb3694;
  color: #ffffff;
}

/* end card filter */

/* products details page */
.product-gallary-card {
  height: 470px !important;
  /* width: 370px; */
  border-radius: 29px;
  background-color: #ffffff;
}

.cat-text {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  /* text-align: right; */
}

.cat-title {
  color: #555550;
  font-family: Almarai;
  font-size: 14px;
  font-weight: normal;
  text-align: right;
}

.cat-rate {
  color: #ffc107;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

.barnd-text {
  color: #555550;
  font-family: "Almarai";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
}

.color {
  cursor: pointer;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.product-description {
  color: #555550;
  font-family: Almarai;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
}

.product-price {
  height: 46px;
  width: 150px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 9px;
  color: #555550;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  background-color: #ffffff;
}

.product-cart-add {
  cursor: pointer;
  text-align: center;
  height: 45px;
  border-radius: 9px;
  border: none;
  color: #ffffff;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: normal;
}

.product-cart-add:hover {
  color: #bdbaba !important;
}

/* products gallary slide style */
.image-gallery-slide {
  width: 372px;
  /* height: 470px; */
  border-radius: 29px;
}

/* end gallary */
/* end products page */

/* pagination */
.page-link {
  background-color: #f9f9f9 !important;
  color: #9419c1 !important;
  border-radius: 10px !important;
  border: solid 1px #9419c1 !important;
}

.page-link:hover {
  background-color: #9419c1 !important;
  color: white !important;
  border: solid 1px #9419c1 !important;
}

/* end pagination */

/* .navbar {
  background-color: #c992f0 !important;
} */

/* end pagination */

/* category header */
.cat-header {
  height: 100%;
}

.cat-text-header {
  color: #fff;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 23px;
  padding-top: 10px;
  margin-left: 17px;
  cursor: pointer;
}

.cat-text-header:hover {
  color: #555550;
}

/* end categoery header */

/* login and register style */

.user-input {
  box-sizing: border-box;
  height: 37px;
  width: 375px;
  border: 1px solid #979797;
  border-radius: 8px;
}

.title-login {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}

.btn-login {
  height: 46px;
  width: 374px;
  border-radius: 9px;
  border: none;
  color: white;
}

.btn-login:hover {
  color: rgb(206, 204, 204) !important;
}

/* end login and register */

/* cart page */
.cart-title {
  color: #555550;
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  text-align: right;
}

.cart-item-body {
  height: 229px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

.cart-item-body-admin {
  height: 130px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

.copon-input {
  box-sizing: border-box;
  height: 46px;
  width: 90%;
  border: 1px solid #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: normal;
}

.copon-btn {
  cursor: pointer;
  border: none;
  height: 46px;
  padding-top: 5px;
  border-radius: 5px !important;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px !important;
  border-radius: 0 !important;
  color: #ffff !important;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: normal;
}

.cart-checkout {
  height: 250px;
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
}

/* cart end */

/* admin style  */
.sidebar {
  height: 738px;
  width: 100%;
  border-radius: 21px;
  background-color: #ffffff;
}

.admin-side-text {
  color: #000;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.admin-side-text:hover {
  background-color: #cb3694;
  color: white;
  font-size: 14px;
}

.admin-content-text {
  /* color: #555550; */
  font-family: Almarai;
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 28px;
  /* text-align: right; */
}

.item-delete-edit {
  color: #979797;
  font-family: "Almarai";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  cursor: pointer;
}

.text-form {
  color: #979797;
  font-family: "Almarai";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.input-form {
  height: 37px;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.input-form-area {
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.select {
  box-sizing: border-box;
  height: 40px;
  color: #979797;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 8px;
}

.btn-save {
  border: none;
  color: white;
  height: 46px;
  width: 150px;
  border-radius: 9px;

}

.btn-save:hover {
  color: rgb(164, 164, 164);
}

.btn-a {
  border: none;
  color: white;
  height: 45px;
  border-radius: 9px;
}

.btn-a:hover {
  color: rgb(164, 164, 164);
}

/* end admin  */

/* rates  */

.rate-container {
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.rate-count {
  color: #979797;
  font-family: "Almarai";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
}

.rate-name {
  color: #555550;
  font-family: "Almarai";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.rate-description {
  color: #555550;
  font-family: "Almarai";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

/* end rate */

/* user order */
.user-order {
  height: 100%;
  width: 100%;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.order-title {
  font-family: "Almarai";
  font-size: 16px;
  font-weight: bold;
}

.stat {
  color: #979797;
  font-family: Almarai;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
}

/* end user order */

.user-address-card-shipping-2 {
  height: 200px;
  width: 100%;
  border-radius: 7px;

  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

/* user Address */
.user-address-card-2 {
  height: 145px;
  width: 100%;
  border-radius: 7px;

  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.user-address-card {
  height: 235px;
  width: 100%;
  border-radius: 7px;

  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.user-address-card-3 {
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

.btn-add-address {
  border: none;
  height: 50px;
  width: 100%;
  color: white;
  border-radius: 9px;
}

/* user address */

/* user card data */
.user-data {
  height: 340px;
  width: 100% i !important;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(151, 151, 151, 0.5);
}

/* end user data */

/* filter side style */
.filter-title {
  color: #555550;
  font-family: "Almarai";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
}

.filter-sub {
  color: #555550;
  font-family: "Almarai";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
}

/* end filter */

/* change language */
.langText {
  font-family: 'Aref Ruqaa', serif;
  font-size: 16px;
  line-height: 1;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.dropdown-toggle {
  color: rgba(0, 0, 0, .55) !important;
  font-size: 16px !important;
  font-weight: bold !important;
  margin-top: 14px;
}

.dropdown-menu {
  /* background-color: #c992f0 !important;  */
  color: white !important;
}


.dropdown-menu show{
  
}

.dropdown-item {
  color: white !important;
  text-align: center !important;
}

.dropdown-item:hover {
  color: #212529 !important;
  background-color: white !important;
}

.badge {
  font-size: 1em !important;
  font-weight: bold !important;
}


.product-cart-add-icon {
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  border: none;
  color: #ffffff;
  font-family: "Almarai";
  font-weight: normal;
  font-size: 14px;
}

.product-cart-add-icon:hover {
  color: #bdbaba !important;
}


/* Found Useful -> Please Subscribe my Youtube Channel
https://bit.ly/3m9avif
*/

/*==================== 
	Footer 
====================== */

/* Main Footer */
footer .main-footer {
  padding: 20px 0;
  background: #252525;
}

footer ul {
  padding-left: 0;
  list-style: none;
}

/* Copy Right Footer */
.footer-copyright {
  background: #fff;
  padding: 5px 0;
}

.footer-copyright .logo {
  display: inherit;
}

.footer-copyright nav {
  float: right;
  margin-top: 5px;
}

.footer-copyright nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-copyright nav ul li {
  border-left: 1px solid #505050;
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 8px;
}

.footer-copyright nav ul li a {
  color: #969696;
}

.footer-copyright nav ul li:first-child {
  border: medium none;
  padding-left: 0;
}

.footer-copyright p {
  color: #969696;
  margin: 2px 0 0;
}

/* Footer Top */
.footer-top {
  background: #252525;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 3px solid #222;
}

/* Footer transparent */
footer.transparent .footer-top,
footer.transparent .main-footer {
  background: transparent;
}

footer.transparent .footer-copyright {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
}

/* Footer light */
footer.light .footer-top {
  background: #f9f9f9;
}

footer.light .main-footer {
  background: #f9f9f9;
}

footer.light .footer-copyright {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3);
}

/* Footer 4 */
.footer- .logo {
  display: inline-block;
}

/*==================== 
	Widgets 
====================== */
.widget {
  padding: 20px;
  margin-bottom: 40px;
}

.widget.widget-last {
  margin-bottom: 0px;
}

.widget.no-box {
  padding: 0;
  background-color: transparent;
  margin-bottom: 40px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
}

.widget.subscribe p {
  margin-bottom: 18px;
}



.widget li a:hover {
  color: #4b92dc !important;
}

.widget-title {
  margin-bottom: 20px
}

.widget-title span {
  background: #839FAD none repeat scroll 0 0;
  display: block;
  height: 1px;
  margin-top: 25px;
  position: relative;
  width: 20%;
}

.widget-title span::after {
  background: inherit;
  content: "";
  height: inherit;
  position: absolute;
  top: -4px;
  width: 50%;
}

.widget-title.text-center span,
.widget-title.text-center span::after {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.widget .badge {
  float: right;
  background: #7f7f7f;
}

.typo-light h1,
.typo-light h2,
.typo-light h3,
.typo-light h4,
.typo-light h5,
.typo-light h6,
.typo-light p,
.typo-light div,
.typo-light span,
.typo-light small {
  color: #fff;
}

ul.social-footer2 {
  margin: 0;
  padding: 0;
  width: auto;
}

ul.social-footer2 li {
  display: inline-block;
  padding: 0;
}

ul.social-footer2 li a:hover {
  background-color: #cb3694;
}

ul.social-footer2 li a {
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
}

.btn {
  background-color: #ff8d1e;
  color: #fff;
}

.btn:hover,
.btn:focus,
.btn.active {
  background: #4b92dc;
  color: #fff;
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 250ms ease-in-out 0s;
  -moz-transition: all 250ms ease-in-out 0s;
  -ms-transition: all 250ms ease-in-out 0s;
  -o-transition: all 250ms ease-in-out 0s;
  transition: all 250ms ease-in-out 0s;

}


.msg-input {
  box-sizing: border-box;
  height: 46px;
  width: 50%;
  border: 1px solid #979797;
  font-family: "Almarai";
  font-size: 12px;
  font-weight: normal;
}

.text-area-input {
  box-sizing: border-box;
  width: 375px;
  border: 1px solid #979797;
  border-radius: 8px;
}

.text-user-input-footer {
  box-sizing: border-box;
  width: 150px;
  border: 1px solid #979797;
  border-radius: 8px;
  font-size: small;
  padding: 2px;
}

.btn-login-footer {
  height: 30px;
  width: 70px;
  border-radius: 9px;
  border: none;
  color: white;
  background-color: #cb3694;
  font-size: small;

}

.admin-content-text-data-table {
  color: #555550;
  font-family: Almarai;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: left;
}

.input-input-form {
  height: 37px;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: #f9f9f9;
}


.main-container {
  display: flex;
}

main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebarMenu {
  color: white;
  height: 100vh;
  overflow-y: auto;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logoMenu {
  font-size: 18px;
  line-height: 0;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration: none;

}

.link:hover {
  border-right: 4px solid white;
  background: white;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  color: '#cb3694';
}

.active {
  border-right: 4px solid white;
  background: #ccc;
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}


button.print-button {
  width: 100px;
  height: 100px;
  float: left;


}

span.print-icon,
span.print-icon::before,
span.print-icon::after,
button.print-button:hover .print-icon::after {
  border: solid 4px #333;
}

span.print-icon::after {
  border-width: 2px;
}

button.print-button {
  position: relative;
  padding: 0;
  border: 0;

  border: none;
  background: transparent;
}

span.print-icon,
span.print-icon::before,
span.print-icon::after,
button.print-button:hover .print-icon::after {
  box-sizing: border-box;
  background-color: #fff;
}

span.print-icon {

  /* position: relative;
  display: inline-block;  
  padding: 0;
  margin-top: 20%;

  width: 60%;
  height: 35%;
  background: #fff;
  border-radius: 20% 20% 0 0; */
  position: relative;
  display: inline-block;
  padding: 0;
  margin-top: 2%;
  width: 50%;
  height: 30%;
  background: #fff;
  border-radius: 20% 20% 0 0;
}

span.print-icon::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 12%;
  right: 12%;
  height: 110%;

  transition: height .2s .15s;
}

span.print-icon::after {
  content: "";
  position: absolute;
  top: 55%;
  left: 12%;
  right: 12%;
  height: 0%;
  background: #fff;
  background-repeat: no-repeat;
  background-size: 70% 90%;
  background-position: center;
  background-image: linear-gradient(to top,
      #fff 0, #fff 14%,
      #333 14%, #333 28%,
      #fff 28%, #fff 42%,
      #333 42%, #333 56%,
      #fff 56%, #fff 70%,
      #333 70%, #333 84%,
      #fff 84%, #fff 100%);

  transition: height .2s, border-width 0s .2s, width 0s .2s;
}

button.print-button:hover {
  cursor: pointer;
}

button.print-button:hover .print-icon::before {
  height: 0px;
  transition: height .2s;
}

button.print-button:hover .print-icon::after {
  height: 120%;
  transition: height .2s .15s, border-width 0s .16s;
}

.mapContainer {
  width: 100%;
  height: 300px;
}

/*======================
    404 page
=======================*/


.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}




._failed {
  border-bottom: solid 4px red !important;
}

._failed i {
  color: red !important;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success i {
  font-size: 55px;
  color: #28a745;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0px;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}

#upload-photo-2{
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#upload-photo-3{
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#upload-photo-4{
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#upload-photo-5{
  opacity: 0;
  position: absolute;
  z-index: -1;
}

#upload-photo-6{
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.quill{
  height: 158px;
  margin-bottom: 6%;
}